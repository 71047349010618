<template>
  <div class="main-container">
    <span class="text-5xl text-center block"></span>
    <a
      :href="school_identity.instagram_url"
      class="text-xl md:text-2xl block text-center mb-12"
    >
      <section class="mr-2 sm:inline-block">
        <fa-icon :icon="['fab', 'instagram']"></fa-icon>
        <span class="ml-2">Instagram</span>
      </section>
      <span class="font-bold">@sditalmadinah_pontianak</span>
    </a>

    <div
      class="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 max-w-screen-lg mx-auto"
    >
      <a
        :href="`https://www.instagram.com/p/${item.node.shortcode}`"
        class="rounded bg-white relative mb-4 w-36 h-36 overflow-hidden mx-auto"
        v-for="(item, key) in feeds"
        :key="`news-${key}`"
      >
        <img
          loading="lazy"
          :src="item.node.thumbnail_resources[0].src"
          :alt="`Gambar ${item.title}`"
          class="rounded-t shadow mx-auto"
        />
      </a>
    </div>
  </div>
</template>

<script>
import InstagramFeed from "@jsanahuja/instagramfeed";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      feeds: [],

      name: null,
    };
  },
  methods: {
    fetchFeed() {
      let vm = this;

      new InstagramFeed({
        username: "sditalmadinah_pontianak",
        callback(data) {
          vm.name = data.full_name;

          vm.feeds = data.edge_owner_to_timeline_media.edges;
        },
      });
    },
  },
  computed: {
    ...mapGetters(["school_identity"]),
  },
  mounted() {
    this.fetchFeed();
  },
  name: "InstagramGallery",
};
</script>

<style lang="scss" scoped>
.main-container {
  @apply pb-16 pt-16 p-2 z-10 relative bg-white;
}
</style>